import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { SwiperSlide } from 'swiper/react';
import qs from 'query-string';
import { useIntl } from 'react-intl';

// Components
import Slider from '../../../components/Slider';

// State
import { applyStock } from '../../../state/modules/stock/actions';

// Router
import { getRoute } from '../../../entry/routes';

// Utils
import { customScrollTo } from '../../../utils/customScrollTo';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';

// Styles
import styles from './MainSlider.styl';
import { sendEventBannerView, sendEventBannerClick } from '../../../utils/metrics';
import { isMovie10Theme } from '../../../state/modules/city/selectors';
import PromotionMovieDecor from '../../../components/PromotionMovie10/PromotionMovieDecor';
import { useInView } from 'react-intersection-observer';

const cx = classNames.bind(styles);

export default function MainSlider(props) {
  const { className, topRef, view } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const stockCode = useSelector(state => state.stock.data.code);
  const bannerList = useSelector(state => state.banner);
  const isMovieActive = useSelector(isMovie10Theme);
  const { ref, inView } = useInView();
  const [swiperState, setSwiperState] = useState(null);

  const intl = useIntl();

  const { bannerId, category } = qs.parse(location.search, { parseNumbers: true });
  const bannersList = bannerList.list.filter(banner => banner.type === 'slider');
  const bannerInitialIndex = bannersList.findIndex(banner => banner.id === bannerId);

  const onBannerClick = (banner, idx) => {
    sendEventBannerClick(idx + 1);
    // переходим по линку после нажатия
    if (banner.link) {
      const urlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

      if (urlRegex.test(banner.link.trim())) {
        Object.assign(document.createElement('a'), { href: banner.link, target: '_blank' }).click();
      } else {
        history.push(getRoute(banner.link));
      }
      // применяем промокод после нажатия
    } else if (banner.stock && stockCode !== banner.stock) {
      dispatch(applyStock({ stock_code: banner.stock }));

      if (topRef?.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const onBannerChanged = useCallback(
    swiperData => {
      setSwiperState({ ...swiperData });
    },
    [setSwiperState],
  );

  useEffect(() => {
    if (inView && swiperState) {
      sendEventBannerView(swiperState?.realIndex + 1);
    }
  }, [swiperState, inView]);

  useEffect(() => {
    if (category) {
      const currentElement = document.getElementById(category);

      if (currentElement) {
        customScrollTo(currentElement, { block: 'start' });
      }

      history.replace({ search: qs.exclude(location.search, ['category']) });
    }
  }, [category]);

  const swiperParams = {
    slidesPerView: view === 'desktop' ? 1.75 : 1,
    spaceBetween: 20,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    navigation: true,
    initialSlide: bannerInitialIndex === -1 ? 0 : bannerInitialIndex,
    preloadImages: false,
    lazy: { loadPrevNext: true, loadOnTransitionStart: true },
    watchSlidesVisibility: true,
  };

  const handleSpanClick = (e, text) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(text)
      .then(() => setGlobalMessage(intl.formatMessage({ id: 'message.copy' }), { type: 'success' }))
      .finally();
  };

  const isPhone = useMemo(() => view === 'phone', [view]);
  return (
    <div className={cx('MainSlider', `MainSlider_${view}`, className)} ref={ref}>
      {isMovieActive && (
        <PromotionMovieDecor srcView="2" className={cx('MainSlider__MovieDecor_filter')} />
      )}
      <div className={cx('MainSlider__header')} />
      {bannerList.list.length > 0 && (
        <Slider
          swiperParams={swiperParams}
          isOutsidePagination
          onSwiperStateChangedCb={onBannerChanged}
        >
          {bannersList.map((banner, idx) => (
            <SwiperSlide
              key={banner.image_full}
              className={cx('MainSlider__item')}
              onClick={() => onBannerClick(banner, idx)}
              tag="button"
            >
              {({ isActive }) => (
                <div className={cx('MainSlider__item-wrapper')}>
                  <picture>
                    <source
                      data-srcset={isPhone ? banner.image_mobile_webp : banner.image_full_webp}
                      type="image/webp"
                    />
                    <img
                      className={`swiper-lazy ${cx('MainSlider__item-img', {
                        'MainSlider__item-img_active': isActive,
                      })}`}
                      data-srcset={isPhone ? banner.image_mobile : banner.image_full}
                      alt="banner"
                    />
                  </picture>
                  {((isActive && isPhone && banner.ad_token_image_mobile) ||
                    (!isPhone && banner.ad_token_image_full)) && (
                    <span
                      className={cx('MainSlider__item-caption')}
                      onClick={e => {
                        const text = isPhone
                          ? banner.ad_token_image_mobile
                          : banner.ad_token_image_full;

                        handleSpanClick(e, text);
                      }}
                    >
                      {isPhone ? banner.ad_token_image_mobile : banner.ad_token_image_full}
                    </span>
                  )}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Slider>
      )}
      {bannerList.isLoading ? (
        <div className={cx('MainSlider__preloader')}>
          <div className={cx('MainSlider__spinner')} />
        </div>
      ) : null}
    </div>
  );
}

MainSlider.defaultProps = {
  className: '',
  topRef: undefined,
};

MainSlider.propTypes = {
  className: PropTypes.string,
  topRef: PropTypes.object,
  view: PropTypes.string.isRequired,
};
